;(function meganav(d: Document) {
  d.addEventListener('mobile-menu-opened', () => {
    showModalBg()
  })
  d.addEventListener('mobile-menu-closed', () => {
    slideClose()
    hideModalBg()
  })

  const timeout_ms = 200
  const modalBg: HTMLElement = d.querySelector('[data-meganav-bg]')
  const mobileMenu: HTMLElement = d.querySelector('#mobile-menu')
  const timeouts: Array<any> = []

  const d_triggers = Array.from(d.querySelectorAll('#header-desktop [data-meganav-trigger]')).reduce(
    (acc: object, el: HTMLElement) => {
      el.addEventListener('mouseenter', (ev: MouseEvent & { target: HTMLElement }) => {
        clearTimeouts()
        showModalBg()
        open(ev.target.dataset.meganavTrigger)
      })
      el.addEventListener('mouseleave', (_ev: MouseEvent & { target: HTMLElement }) => {
        timeouts.push(
          setTimeout(() => {
            close()
            hideModalBg()
          }, timeout_ms)
        )
      })
      acc[el.dataset.meganavTrigger] = el
      return acc
    },
    {}
  )

  Array.from(d.querySelectorAll('#header-mobile [data-meganav-trigger]')).reduce((acc: object, el: HTMLElement) => {
    el.addEventListener('click', (ev: MouseEvent & { currentTarget: HTMLElement }) => {
      ev.preventDefault()
      slideOpen(ev.currentTarget.dataset.meganavTrigger)
    })
    acc[el.dataset.meganavTrigger] = el
    return acc
  }, {})

  const d_content = Array.from(d.querySelectorAll('#header-desktop [data-meganav-content]')).reduce(
    (acc: object, el: HTMLElement) => {
      el.addEventListener('mouseenter', (ev: MouseEvent & { target: HTMLElement }) => {
        clearTimeouts()
        showModalBg()
        open(ev.target.dataset.meganavContent)
      })
      el.addEventListener('mouseleave', (_ev: MouseEvent & { target: HTMLElement }) => {
        timeouts.push(
          setTimeout(() => {
            close()
            hideModalBg()
          }, timeout_ms)
        )
      })
      acc[el.dataset.meganavContent] = el
      return acc
    },
    {}
  )

  const m_content = Array.from(d.querySelectorAll('#header-mobile [data-meganav-content]')).reduce(
    (acc: object, el: HTMLElement) => {
      el.firstElementChild.addEventListener('click', (ev: MouseEvent & { target: HTMLElement }) => {
        ev.preventDefault()
        slideClose()
      })
      acc[el.dataset.meganavContent] = el
      return acc
    },
    {}
  )

  const open = (s: string) => {
    Object.entries(d_triggers).forEach(([key, el]: [string, HTMLElement]) => {
      key === s ? el.classList.add('active') : el.classList.remove('active')
    })
    Object.entries(d_content).forEach(([key, el]: [string, HTMLElement]) => {
      key === s ? el.classList.remove('hidden') : el.classList.add('hidden')
    })
  }

  const close = () => {
    Object.values(d_triggers).forEach((el: HTMLElement) => {
      el.classList.remove('active')
    })
    Object.values(d_content).forEach((el: HTMLElement) => {
      el.classList.add('hidden')
    })
  }

  const slideOpen = (s: string) => {
    Object.entries(m_content).forEach(([key, el]: [string, HTMLElement]) => {
      key === s ? el.classList.remove('hidden') : el.classList.add('hidden')
    })
    mobileMenu.classList.add('open')
  }

  const slideClose = () => {
    Object.values(m_content).forEach((el: HTMLElement) => {
      setTimeout(() => {
        el.classList.add('hidden')
      }, 200)
    })
    mobileMenu.classList.remove('open')
  }

  const showModalBg = () => {
    modalBg.classList.remove('hidden')
  }

  const hideModalBg = () => {
    modalBg.classList.add('hidden')
  }

  const clearTimeouts = () => {
    timeouts.forEach((t) => clearTimeout(t))
  }
})(document)
